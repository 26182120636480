import { getCurrentBoatId } from '@/app/selectors';
import { getAmountFormatter } from '@/dictionary/selector';
import { getAnalyticsClientArticlesListValues } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/selector';
import { getAnalyticsFilters } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getCurrentBoatId, getAnalyticsFilters, getAnalyticsClientArticlesListValues, getAmountFormatter],
  (boatId, filters, items, amountFormatter) => ({
    boatId,
    filters,
    items,
    amountFormatter,
  })
);
