import { IRouteParams, ROUTES } from '@/app/consts/routes';
import { getCurrentBoatId } from '@/app/selectors';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  ANALYTICS_EXPENSES_VIEW,
  ANALYTICS_INNER_TABS,
  ANALYTICS_EXPENSES_VIEW_QUERY_KEY,
} from '@/pages/analytics/AnalyticsInner/consts';
import { ANALYTICS_TABS } from '@/pages/analytics/consts';
import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'components/Intl';
import React from 'react';
import { useParams } from 'react-router';
import { DownloadButton, ViewDisplay } from './components';
import useStyles from './styles';

const AnalyticsInnerTabs = () => {
  const boatId = useAppSelector(getCurrentBoatId);
  const params = useParams<IRouteParams>();
  const styles = useStyles();

  const tabs: ITab[] = [
    {
      value: ANALYTICS_INNER_TABS.EXPENSES,
      label: <FormattedMessage id="analytics.tabs.expenses" />,
      dataTestId: 'analytics_page-tabs-expenses',
      to: buildURL(`/${boatId}/${ROUTES.ANALYTICS}/${ANALYTICS_TABS.ANALYTICS}/${ANALYTICS_INNER_TABS.EXPENSES}`, {
        [ANALYTICS_EXPENSES_VIEW_QUERY_KEY]: ANALYTICS_EXPENSES_VIEW.MONTH,
      }),
    },
    {
      value: ANALYTICS_INNER_TABS.PLAN_FACT,
      label: <FormattedMessage id="analytics.tabs.plan_vs_fact" />,
      dataTestId: 'analytics_page-tabs-plan_fact',
      to: buildURL(`/${boatId}/${ROUTES.ANALYTICS}/${ANALYTICS_TABS.ANALYTICS}/${ANALYTICS_INNER_TABS.PLAN_FACT}`),
    },
  ];

  return (
    <Grid item container sx={styles.container}>
      <Tabs size="small" tabs={tabs} active={params.analyticsInnerTab} />

      {params.analyticsInnerTab === ANALYTICS_INNER_TABS.PLAN_FACT && (
        <Grid item container gap={1} width="auto" wrap="nowrap">
          <ViewDisplay />
          <Divider orientation="vertical" sx={styles.divider} flexItem />

          <Grid item container width="auto" marginLeft={2}>
            <DownloadButton />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AnalyticsInnerTabs;
