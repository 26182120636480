import { getCostArticleMappingEnabled, getCurrentBoatId } from 'app/selectors';
import { getAnalyticsFilters } from 'pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getAnalyticsFilters, getCurrentBoatId, getCostArticleMappingEnabled],
  (filters, boatId, costArticleMappingEnabled) => ({
    dateFrom: filters.dateFrom,
    dateTo: filters.dateTo,
    boatId,
    costArticleMappingEnabled,
  })
);
