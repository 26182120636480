import { setCurrentBoatConfig, toggleUserCostArticlesMapping } from '@/app/actions';
import { responseError } from '@/app/sagas';
import { getCostArticleMappingEnabled } from '@/app/selectors';
import {
  getClientArticlesPlanFact,
  resetClientArticlesPlanFact,
  setClientArticlesPlanFactLoading,
} from '@/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/actions';
import { Action } from 'redux-actions';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* turnOnCostArticlesMappingFromAnalyticsPlanFact() {
  try {
    yield put(toggleUserCostArticlesMapping());
    yield take(setCurrentBoatConfig);

    window.location.reload();
  } catch (e) {
    yield call(responseError, e);
  }
}

function* getAnalyticsPlanFactList() {
  const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

  if (costArticleMappingEnabled) {
    yield put(getClientArticlesPlanFact(null));
  } else {
    // TODO: добавить когда будет делать План-Факт по нашим артиклам
    // yield put(getMyoceanArticlesPlanFact(null));
  }
}

function* resetAnalyticsPlanFactList() {
  const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

  if (costArticleMappingEnabled) {
    yield put(resetClientArticlesPlanFact());
  } else {
    // TODO: добавить когда будет делать План-Факт по нашим артиклам
    // yield put(resetMyoceanArticlesPlanFact());
  }
}

function* setAnalyticsPlanFactListLoading({ payload }: Action<boolean>) {
  const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

  if (costArticleMappingEnabled) {
    yield put(setClientArticlesPlanFactLoading({ loading: payload }));
  } else {
    // TODO: добавить когда будет делать План-Факт по нашим артиклам
    // yield put(setMyoceanArticlesPlanFactLoading({ loading: payload }));
  }
}

export default [
  takeLatest(actions.turnOnCostArticlesMappingFromAnalyticsPlanFact, turnOnCostArticlesMappingFromAnalyticsPlanFact),
  takeLatest(actions.getAnalyticsPlanFact, getAnalyticsPlanFactList),
  takeLatest(actions.resetAnalyticsPlanFact, resetAnalyticsPlanFactList),
  takeLatest(actions.setAnalyticsPlanFactLoading, setAnalyticsPlanFactListLoading),
];
