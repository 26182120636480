import { call, put, select, takeLatest } from 'redux-saga/effects';
import { POST } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { getCurrentBoatId } from '@/app/selectors';
import { formatTime, getTimestampInMs } from '@/app/utils/dates';
import { CostClientResponseSchemaFlat } from '@/generated';
import { ICostClientResponseSchemaFlat } from '@/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/types';
import { IAnalyticsFiltersState } from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/reducer';
import {
  getAnalyticsFilters,
  getAnalyticsFiltersSelectedMonths,
} from '@/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import * as actions from './actions';

function* getClientArticlesAnalyticsExpensesList() {
  try {
    const filters: IAnalyticsFiltersState = yield select(getAnalyticsFilters);
    const boatId: number = yield select(getCurrentBoatId);

    const response: CostClientResponseSchemaFlat[] = yield call(POST, '/v2/client_cost_articles/get_analytics', {
      // TODO: временный параметр который бэк добавил чтобы ручка не ломалась, в финальной версии нужно будет удалить
      default_currency_only: false,
      boat_id: boatId,
      cost_center_ids: Array.isArray(filters.costCenters)
        ? filters.costCenters
        : filters.costCenters
        ? [filters.costCenters]
        : undefined,
      variability: filters.variability ?? undefined,
      date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
      date_to: filters.dateTo ? Number(filters.dateTo) : undefined,
      per_month: true,
    });

    const selectedMonths: string[] = yield select(getAnalyticsFiltersSelectedMonths);

    const formattedValues: ICostClientResponseSchemaFlat[] = response.map((costArticle) => ({
      ...costArticle,

      formattedPricesPerMonth: selectedMonths.map((month) => {
        if (!costArticle.price_per_month) {
          return {
            monthName: month,
            total: 0,
          };
        }

        // TODO: хочется убрать этот find, но бэк отправляет массив только с теми месяцами в которых есть данные поэтому пока что приходится его использовать
        const pricePerMonth = costArticle.price_per_month.find(
          (item) => formatTime(getTimestampInMs(item.start_month), { dateFormat: 'MMMM-YY' }) === month
        );

        return {
          monthName: month,
          total: pricePerMonth?.total_price ?? 0,
        };
      }),
    }));

    yield put(
      actions.setClientArticlesAnalyticsExpensesList({
        value: formattedValues,
        hasData: Boolean(response.length),
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setClientArticlesAnalyticsExpensesList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

export default [takeLatest(actions.getClientArticlesAnalyticsExpensesList, getClientArticlesAnalyticsExpensesList)];
